@import './vars';
.navbar {
    width: 100%;
    height: 80px;
    .container {
        height: 100%;
        justify-content: space-between;
        align-items: center;
    }
}

.navbar__btn {
    height: 50px;
    margin-left: auto;
    margin-right: 0;
}

.show-logo {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 100;
    background: $accentColor;

}

@media (min-width: $screenDesktopSmall) {
    .navbar__logo {
        display: none;
    }

    .show-logo {
        filter: drop-shadow(0px 0px 21px rgba(0, 0, 0, 0.5));
        .navbar__logo {
            display: initial;
        }
    }
}
