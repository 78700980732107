@import './vars';
.form-group {
    display: block;
    width: 100%;
    margin-bottom: 20px;
}
.form-control {
    display: block;
    width: 100%;
    height: 50px;
    padding: 16px 24px;
    border: 1px solid $mainColor;
    color: #fff;
    outline: none;
    background-color: transparent;
    &:hover, &:focus {
        border-width: 2px;
        outline: none;
    }
    &textarea {
       height: auto; 
       min-height: 50px;
    }
    &::placeholder {
        color: #f2f2f2;
        font-size: 1.5em;
        font-size: 14px;
        font-family: $fontFamily;
    }
}