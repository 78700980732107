@import './vars';
@import './typography';
@import './navbar';
@import './footer';
@import './modal';
@import './form_controls';

@font-face {
    font-family: 'Abitare Sans';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url('../fonts/abitare-sans-150.ttf');
}

@font-face {
    font-family: 'Abitare Sans';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('../fonts/abitare-sans-600.ttf');
}



* {
    box-sizing: border-box;
}
body {
    box-sizing: border-box;
    background-color: $accentColor;
    overflow-y: scroll;
}

.container {
    padding: 0 15px;
    margin: auto;
    &_flex {
        display: flex;
    }
}

@media (min-width: $screenDesktop) {
    .container {
        width: 1200px;
    }
}

@media (min-width: $screenTablet) {
    .xs-only {
        display: none !important;
    }
}

@media (max-width: $screenDesktopSmall - 1px) {
    .desktop-only {
        display: none !important;
    }
}

@media (max-width: $screenTablet - 1px) {
    .hidden-xs {
        display: none !important;
    }
}

.text-center {
    text-align: center;
}

ul {
    list-style-type: circle;
    padding-left: 16px;
    list-style-position: outside;
    li {
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
