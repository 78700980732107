@import './vars';
body {
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 150;
    font-size: 16px;
    line-height: 18px;
    color: $accentColor;
}
h1,
h2,
h3 {
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0;
}
h1 {
    font-size: 22px;
    line-height: 24.89px;
}

h2 {
    font-size: 22px;
    line-height: 24.89px;
}

h3 {
    font-size: 24px;
    line-height: 27px;
}

@media (min-width: $screenDesktopSmall) {
    h1 {
        font-size: 50px;
        line-height: 58px;
    }

    
}

@media (min-width: $screenDesktop) {
    h1 {
        font-size: 60px;
        line-height: 68px;
    }

    h2 {
        font-size: 40px;
        line-height: 45px;
    }
}


.btn {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    border: 1px solid $mainColor;
    background-color: $mainColor;
    color: $accentColor;
    cursor: pointer;
    transition: background-color 0.2s;
    font-size: 20px;
    line-height: 30px;
    outline: none;
    padding: 10px 20px;
    box-shadow: 0px 10px 20px rgba(41, 41, 42, 0.07);
    font-weight: 600;
    &:hover {
        background-color: transparent;
        color: $mainColor;
        box-shadow: none;
    }
}
