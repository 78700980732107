@import './vars';
.modal {
    display: none;
}

.modal.is-open {
    display: block;
}
.modal__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
}

.modal__container {
    background-color: $accentColor;
    padding: 20px;
    width: 280px;
    max-height: 100vh;
    overflow-y: auto;
    box-sizing: border-box;
}

.modal__header {
    margin-bottom: 20px;
    text-align: center;
    &__subheader {
        font-size: 18px;
        line-height: 20px;
        text-align: center;
        color: $mainColor;
    }
}

.modal__title {
    color: $mainColor;
    font-size: 30px;
    line-height: 34px;
    margin-bottom: 20px;
}
.modal__close-wrap {
    text-align: right;
    margin-bottom: 10px;
}
.modal__close {
    border: none;
    outline: none;
    padding: 4px;
    background: transparent;
    cursor: pointer;
}

.modal__content {
}

/**************************\
    Demo Animation Style
  \**************************/
@keyframes mmfadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes mmfadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes mmslideIn {
    from {
        transform: translateY(15%);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes mmslideOut {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(-10%);
    }
}

.micromodal-slide {
    display: none;
}

.micromodal-slide.is-open {
    display: block;
}

.micromodal-slide[aria-hidden='false'] .modal__overlay {
    animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden='false'] .modal__container {
    animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden='true'] .modal__overlay {
    animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden='true'] .modal__container {
    animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide .modal__container,
.micromodal-slide .modal__overlay {
    will-change: transform;
}
