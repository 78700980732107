@import './vars';
.footer {
    padding: 25px;
    background: $mainColor;
    .container_flex {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
}

.footer__logo {
    display: inline-block;
    margin-bottom: 20px;
}

@media (min-width: $screenDesktopSmall) {
    
    .footer {
        padding: 0;
        height: 100px;
        .container_flex {
            height: 100%;
            flex-direction: row;
            justify-content: space-between;
            text-align: left;
        }
    }
    .footer__logo {
        margin-bottom: 0;
    }
}